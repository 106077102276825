import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import { Link } from "gatsby"
import Nav from 'react-bootstrap/Nav'
import Navbar from 'react-bootstrap/Navbar'

export default function Header() {

  const data = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
          }
        }
      }
    `
  )

  return (

    <Navbar bg="light" fixed="top" expand="lg">
      <Navbar.Brand href="/">
        {data.site.siteMetadata.title}
      </Navbar.Brand>

      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="ml-auto" id="collnav">
          <Link className="nav-link" to="/">Accueil</Link>
          <Link className="nav-link" to="/prestations">Prestations</Link>
          <Link className="nav-link" to="/tarifs">Tarifs</Link>
          <Link className="nav-link" to="/contact">Contact</Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  )
}