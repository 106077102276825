import React from "react"
import PropTypes from "prop-types"

import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Container from 'react-bootstrap/Container'
import { Link } from "gatsby"

const Footer = ({ siteTitle }) => (

    <footer className="general">
        <Container>
            <br></br>
            <Row>
                <Col sm className="colFooter">
                        <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-geo-alt-fill" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"/>
                        </svg>
                        <br></br>
                        
                        <a className="footerlinks"  href="https://goo.gl/maps/stAv4yboZcyS1DSX6">18 rue de l'Yser, 33 400 Talence</a>

                        
                </Col>
                <Col sm className="colFooter">
                          <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-telephone-fill" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" d="M2.267.98a1.636 1.636 0 0 1 2.448.152l1.681 2.162c.309.396.418.913.296 1.4l-.513 2.053a.636.636 0 0 0 .167.604L8.65 9.654a.636.636 0 0 0 .604.167l2.052-.513a1.636 1.636 0 0 1 1.401.296l2.162 1.681c.777.604.849 1.753.153 2.448l-.97.97c-.693.693-1.73.998-2.697.658a17.47 17.47 0 0 1-6.571-4.144A17.47 17.47 0 0 1 .639 4.646c-.34-.967-.035-2.004.658-2.698l.97-.969z"/>
                        </svg>
                        <br></br>
                        <a className="footerlinks"  href="tel:0670647938">06.70.64.79.38</a>

                </Col>
                <Col sm className="colFooter">

                    <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-envelope-fill" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414.05 3.555zM0 4.697v7.104l5.803-3.558L0 4.697zM6.761 8.83l-6.57 4.027A2 2 0 0 0 2 14h12a2 2 0 0 0 1.808-1.144l-6.57-4.027L8 9.586l-1.239-.757zm3.436-.586L16 11.801V4.697l-5.803 3.546z"/>
                        </svg>
                        <br></br>
                        <a className="footerlinks" href="mail:nadine.comblat@gmail.com">nadine.comblat@gmail.com</a>
                </Col>

            </Row>
            
            <Row className='mb-0'>
                <div className="conditions m-0">
                    Conformément à la loi du 30 avril 1946 et du décret n°60665 du 04 juillet 1960
                    et de l’article L489 et celui du 08 octobre 1996,
                    il ne s’agit nullement de massage médical ou de kinésithérapie
                    mais de techniques de bien être dans la relaxation physique et la détente.
                    
                </div>
            </Row>
            <Row className='m-0'>
             <div className="conditions">
            N°siret 528 422 124 000 24, HivaHoa Massage 2023 © | Site réalisé par<Link className="refEtomal ml-1" to='https://etomal.com'>Thibaud Lamothe</Link>
            </div>
            </Row>
    
        </Container>
    </footer>

)

Footer.propTypes = {
    siteTitle: PropTypes.string,
}

Footer.defaultProps = {
    siteTitle: ``,
}

export default Footer


// {/* <Col>
//     <a href="https://hivahoamassage.com/gatsby" className="footerlinks">{siteTitle}</a>
//     {`, `}
//     {new Date().getFullYear()}
//     {/* © */}
//     <br></br>
//     {/* {` - `} */}
//     N°siret 528 422 124 000 24
// </Col> */}