import React from "react"
import PropTypes from "prop-types"

const Bandeau = ({ title, dark, children, urlid }) => (

  <header id={urlid} name ={urlid} className={dark ? "headerDark ": "header"}>
    <div className="bandeaudiv">
      <h1 className= {dark ? "h1BandeauDark" : "h1Bandeau"}>
          {title}
      </h1>
      {children}
    </div>
  </header>
)

Bandeau.propTypes = {
  title: PropTypes.string,
}

Bandeau.defaultProps = {
  title: ``,
}

export default Bandeau
